import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Kad from './pages/Kad';
import Demo from './pages/Demo';
import Live from './pages/Live';
import Success from './pages/Success';
import PaymentFallbackPage from './pages/PaymentFallback';
import Edit from './pages/Edit';
import Checkout from './pages/Checkout';
import Daftar from './pages/Daftar';
import SendEmail from './pages/SendEmail';
import Publish from './pages/Publish';
import SearchLocation from './pages/SearchLocation';
import AddContacts from './pages/AddContacts';
import Reward from './pages/Reward';
import UnderMaintenance from './pages/UnderMaintenance';
import Music from './pages/Music';
import VideoPlayer from './pages/VideoPlayer';
import YTAudio from './components/YTAudio';
import ActivateMusic from './pages/ActivateMusic';
import ActivateHideGift from './pages/ActivateHideGift';
import RSVPList from './components/RSVPList';
import RSVP from './pages/RSVP';
import DaftarNew from './pages/DaftarNew';
import OSMSearch from './components/OSMSearch';
import Dashboard from './pages/Dashboard';
import SalamKaut from './pages/SalamKaut';
import Feedback from './pages/Feedback';
import ActivateSalamKaut from './pages/ActivateSalamKaut';
import UpdateRsvpButton from './pages/UpdateRsvp';
import ActivateRsvp from './pages/ActivateRsvp';
import Checkout2 from './pages/Checkout2';
import Sumbangan from './pages/Sumbangan';
import Theme1 from './themes/theme1/Theme1';
import Manage from './pages/Manage';
import PlusCode from './pages/PlusCode';
import Tema from './pages/Tema';
import GAWrapper from './context/GAWrapper';
import Main from './pages/Main';

function App() {

  return (
    <Router>
      <GAWrapper>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<Main />} />
          {/* <Route path="/" element={<Home />} /> */}
          {/* <Route path="/" element={<UnderMaintenance />} /> */}
          <Route path="/:view/:theme" element={<Kad />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/addcontacts" element={<AddContacts />} />
          <Route path="/daftar" element={<Daftar />} />
          <Route path="/profile" element={<Checkout2 />} />
          <Route path="/publish/:name/:email/:mobile/:total/:promo" element={<Publish />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/:id" element={<Manage />} />
          <Route path="/checkout/:id" element={<Checkout />} />
          <Route path="/sumbangan" element={<Sumbangan />} />
          <Route path="/feedback" element={<Feedback />} />
          <Route path="/salamkaut/:id" element={<SalamKaut />} />
          <Route path="/search" element={<OSMSearch />} />
          <Route path="/admin/aktifkanlagu" element={<ActivateMusic />} />
          <Route path="/admin/hidegift" element={<ActivateHideGift />} />
          <Route path="/admin/salamkaut" element={<ActivateSalamKaut />} />
          <Route path="/admin/updatersvp" element={<UpdateRsvpButton />} />
          <Route path="/admin/rsvp" element={<ActivateRsvp />} />
          <Route path="/rsvp/:id" element={<RSVP />} />
          {/* <Route path="/getaudio" element={<YTAudio />} /> */}
          {/* <Route path="/video" element={<VideoPlayer />} /> */}
          <Route path="/reward" element={<Reward />} />
          <Route path="/send" element={<SendEmail />} />
          <Route path="/kad/:id" element={<Live />} />
          <Route path="/kad/:id/:demoTheme" element={<Live />} />
          <Route path="/edit/:id" element={<Edit />} />
          <Route path="/success/:id" element={<Success />} />
          <Route path="/music/:id" element={<Music />} />
          {/* <Route path="/dashboard/:id" element={<Dashboard />} /> */}
          <Route path="/fallback" element={<PaymentFallbackPage />} />
          <Route path="/theme1" element={<Theme1 />} />
          <Route path="/tema/:id" element={<Tema />} />
          {/* <Route path="/pluscode" element={<PlusCode />} /> */}
          {/* <Route path="/lokasi" element={<SearchLocation />} /> */}
        </Routes>
      </GAWrapper>
    </Router>
  );
}

export default App;
