import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import LogMasuk from '../components/LogMasuk';
import Auth from '../components/Auth';
import Loading from '../components/Loading';
import NavBar2 from '../components/NavBar2';
import UploadQr from '../components/UploadQr';
import EnableGift from '../components/EnableGift';
import EnableSalamKaut from '../components/EnableSalamKaut';
import NavBar from '../components/NavBar';

function SalamKaut() {
  const [login, setLogin] = useState(true);
  const [loading, setLoading] = useState(true);
  const [ownerEmail, setOwnerEmail] = useState('');
  const { id } = useParams();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/clients/${id}`)
      .then(response => {
        const salamkaut_activation = response.data.salamkaut_activation;
        if(salamkaut_activation){
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
  }, [id]);

  return (
    <div>
      <Helmet>
        <title>Salam Kaut | InviteKahwin.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Auth id={id} setLogin={setLogin} setOwnerEmail={setOwnerEmail} />
      {login ? 
        (
          <div>
            <>
              {loading && (
                <Loading />
              )}
              <NavBar2 />
              <div className="md:p-24 flex items-center justify-center">
                <div className="bg-white px-8 py-12 rounded-md md:shadow-lg max-w-lg w-full">
                  <EnableGift />
                  <EnableSalamKaut />
                  <UploadQr setLoading={setLoading} />
                </div>
              </div>
            </>
          </div>
        ) : ( 
          <div>
            <LogMasuk ownerEmail={ownerEmail} setLogin={setLogin} />
          </div>
        )
      }
    </div>
  );
}

export default SalamKaut;
