import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import bg1 from '../img/bg66.jpg';
import ft2 from '../img/ft66.png';
import fb2 from '../img/fb66.png';
import { themes } from '../context/ThemesList';
import NavBar from '../components/NavBar';
import WhatsAppFAB from '../components/WhatsAppFAB';
import { Helmet } from 'react-helmet';
import { FaStar } from 'react-icons/fa';
import Footer from '../components/Footer';

function Home() {
  const [clientCount, setClientCount] = useState(0);
  const [cardCount, setCardCount] = useState(0);
  const [todayWeddingCount, setTodayWeddingCount] = useState(0);
  const [price, setPrice] = useState(50.00);

  
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/settings`)
      .then(response => setPrice(response.data.price))
      .catch(error => console.error('Error fetching settings:', error));
  }, []);

  useEffect(() => {
    totalUsers();
    totalCards();
    totalTodayWedding();
  }, []);

  const totalUsers = () => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/totalusers`)
      .then(response => {
        setClientCount(response.data.count+1211);
      })
      .catch(error => {
        console.error("There was an error fetching the client count!", error);
      });
  };

  const totalCards = () => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/totalcards`)
      .then(response => {
        setCardCount(response.data.count+1211);
      })
      .catch(error => {
        console.error("There was an error fetching the card count!", error);
      });
  };

  const totalTodayWedding = () => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/todayweddings`)
      .then(response => {
        setTodayWeddingCount(response.data.count);
      })
      .catch(error => {
        console.error("There was an error fetching the card count!", error);
      });
  };

  const heroStyle = {
    backgroundImage: `url(${bg1})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  };

  const H2 = "font-playfair-display";
  const fontP = "font-normal";

  const themesRef = useRef(null);
  const navigate = useNavigate();

  const scrollToThemes = () => {
    themesRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSelect = (themeId) => {
    // window.scrollTo(0, 0);
    window.location.href = `/preview/theme${themeId}`;
    // navigate(`/preview/theme${themeId}`);
  };
  
  const handlePreview = (theme) => {
    window.open(`/kad/demoplus/theme${theme}`, '_blank');
  };

  const [visibleThemes, setVisibleThemes] = useState([]); // To store themes to display
  const [loadCount, setLoadCount] = useState(1); // Keeps track of batches loaded
  const loaderRef = useRef(null);

  const themesPerLoad = 10; // Number of themes to load per batch

  useEffect(() => {
    // Load the initial batch
    setVisibleThemes(themes.slice(0, themesPerLoad * loadCount));
  }, [themes, loadCount]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          // Trigger loading the next batch
          setLoadCount((prev) => prev + 1);
        }
      },
      { threshold: 1 }
    );

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, []);

  return (
    <div>
      <Helmet>
        <title>Kad Kahwin Digital Eksklusif</title>
        <meta 
          name="description" 
          content="Kad Kahwin Digital Eksklusif. Siap RSVP, Ucapan, Background Music dan Salam Kaut" 
        />
        <meta 
          name="keywords" 
          content="Kad Kahwin Digital, RSVP, Majlis perkahwinan, majlis pernikahan, kad jemputan, jemputlah, kenduri kesyukuran, tunang, pertunangan, pernikahan, save the date" 
        />
        <link rel="canonical" href="https://invitekahwin.com" />
      </Helmet>
      <NavBar />
      <WhatsAppFAB />
      <div className="py-14 md:py-4 min-h-screen relative flex flex-col justify-center items-center overflow-hidden" style={heroStyle}>
        <div className="z-10 text-center px-4 mt-20 md:p-20 md:w-1/2 sm:w-2/3 w-3/4 text-black">
          <h2 className={`text-3xl ${H2} capitalize`}>
            Kad Kahwin Digital Eksklusif
          </h2>
          <p className={`mt-5 ${fontP} capitalize`}>
            {/* Kini 100% Percuma */}
            Kini siap RSVP, Salam Kaut, Ucapan & Lagu. Paling penting, senang nak guna.
          </p>

          <div className='px-3 py-2 rounded-lg shadow-lg flex items-center justify-between bg-white mt-12'>
            <p>Harga</p>
            <p>RM{price}</p>
          </div>

          <div className='flex flex-col items-center justify-center space-y-2 md:flex-row md:space-x-2 md:space-y-0 mt-12'>
            <a 
              href={`/kad/demoplus`}
              target="_blank"
              className={`w-full py-2 px-3 bg-white text-primary-700 hover:bg-primary-700 hover:text-neutral-50 rounded-lg border-solid border-2 border-primary-700 hover:border-primary-700 relative z-10`}>
              Demo
            </a>
            <a 
              onClick={scrollToThemes}
              className={`w-full py-2 px-3 bg-primary-500 text-neutral-50 hover:bg-primary-700 hover:text-neutral-50 rounded-lg border-solid border-2 border-primary-500 hover:border-primary-700 relative z-10`}>
              Free Trial (1 Jam)
            </a>
            <a 
              href={`/dashboard`}
              target="_blank"
              className={`w-full py-2 px-3 bg-secondary-500 text-neutral-50 hover:bg-secondary-700 hover:text-neutral-50 rounded-lg border-solid border-2 border-secondary-500 hover:border-secondary-700 relative z-10`}>
              Log Masuk
            </a>
          </div>
          {/* <div className='flex items-center justify-center space-x-2 mt-4'>
            <div className="w-full p-2 bg-white rounded-lg shadow-md">
              <h2 className="text-sm font-bold text-secondary-700">Jumlah pengguna</h2>
              <p className="text-xl font-bold text-primary-700">{clientCount}</p>
              <p className="text-sm">orang</p>
            </div>
            <div className="w-full p-2 bg-white rounded-lg shadow-md">
              <h2 className="text-sm font-bold text-secondary-700">Jumlah Kad Tercipta</h2>
              <p className="text-xl font-bold text-primary-700">{cardCount}</p>
            </div>
            <div className="w-full p-2 bg-white rounded-lg shadow-md">
              <h2 className="text-sm font-bold text-secondary-700">Kahwin Hari Ini</h2>
              <p className="text-xl font-bold text-primary-700">{todayWeddingCount}</p>
            </div>
          </div> */}
        </div>
        <img
          src={ft2}
          alt="Top Flower"
          className="absolute top-0 right-0 w-3/5 sm:w-1/2 md:w-auto md:h-screen"
        />
        <img
          src={fb2}
          alt="Bottom Flower"
          className="absolute bottom-0 left-0 w-3/5 sm:w-1/2 md:w-auto md:h-screen"
        />
      </div>
      <div ref={themesRef} className="container mx-auto py-14">
        <h1 className="text-xl font-bold mb-4 text-center">Pilih Tema</h1>
        <div className="flex flex-wrap">
          {visibleThemes.map((theme) => (
            <div
              className="w-1/2 md:w-1/5 p-4 md:p-4"
              key={theme.id} // Key moved here to avoid duplication
            >
              <a onClick={() => handleSelect(theme.id)}>
                <div className="max-w-sm rounded overflow-hidden shadow-lg my-4">
                  <img
                    src={theme.image}
                    alt={theme.title}
                    className="w-full"
                    loading="lazy"
                  />
                </div>
              </a>
              <div className="space-y-2">
                <div className="flex items-center justify-center space-x-2">
                  <button
                    className="text-primary-500 text-sm bg-white border border-primary-500 hover:text-neutral-50 hover:bg-primary-700 p-1 w-full rounded-lg"
                    onClick={() => handlePreview(theme.id)}
                  >
                    Demo
                  </button>
                </div>
                <div className="flex items-center justify-center space-x-2">
                  <button
                    className="text-neutral-50 text-sm bg-primary-500 hover:bg-primary-700 p-1 w-full rounded-lg"
                    onClick={() => handleSelect(theme.id)}
                  >
                    Pilih Tema Ini
                  </button>
                </div>
              </div>
            </div>
          ))}
          {/* Sentinel element */}
          <div ref={loaderRef} className="w-full h-10"></div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
