import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import logo from '../img/logo.png';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { FaAddressBook, FaGift, FaHeart, FaMusic, FaPalette, FaSpinner, FaWhatsapp } from 'react-icons/fa';
import Recommended from '../components/Recommended';

const Manage = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [event, setEvent] = useState('');
  const [walimatulurus, setWalimatulurus] = useState('');
  const [status, setStatus] = useState('');
  const [groomName, setGroomName] = useState('');
  const [brideName, setBrideName] = useState('');
  const [message, setMessage] = useState('');
  const [weddingDate, setWeddingDate] = useState('');
  const [parentName, setParentName] = useState('');
  const [location, setLocation] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [timeLeft, setTimeLeft] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [expired, setExpired] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/kad/${id}`);
        const data = response.data;

        setWalimatulurus(data.walimatulurus);
        setStatus(data.status);
        setGroomName(data.groom_name);
        setBrideName(data.bride_name);
        setWeddingDate(data.wedding_date);
        setParentName(data.parent_name);
        setMessage(data.message);
        setLocation(data.location);
        setCreatedAt(data.created_at);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [id]); 

  useEffect(() => {
    const getEvent = async () => {
      axios.get(`${process.env.REACT_APP_API_BASE_URL}/clients/${id}`)
        .then(response => {
          const event = response.data.event;
          setEvent(event);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    };

    getEvent();

  }, [id]);

  useEffect(() => {
    // Function to calculate the time difference
    const calculateTimeLeft = () => {
      const expirationTime = new Date(createdAt).getTime() + 1 * 60 * 60 * 1000;
      const currentTime = new Date().getTime();
      const difference = expirationTime - currentTime;

      if (difference > 0) {
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);
        setTimeLeft({ hours, minutes, seconds });
      } else {
        // If the expiration time has passed
        setTimeLeft({ hours: 0, minutes: 0, seconds: 0 });
        setExpired(true);
      }
    };

    // Update the countdown every second
    const interval = setInterval(calculateTimeLeft, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [createdAt]);

  const weddingDateSet = new Date(weddingDate);
  const weddingDay = getDayFromDate(weddingDateSet);

  const formattedDate = weddingDateSet.toLocaleDateString('ms-MY', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  // Function to calculate the day from a given date
  function getDayFromDate(date) {
    const days = ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', "Jumaat", 'Sabtu'];
    const dayIndex = date.getDay();
    return days[dayIndex];
  }
  
  const url = `https://kad.invitekahwin.com/${id}`;
  const edit = `/edit/${id}`;
  const rsvp = `/rsvp/${id}`;
  const music = `/music/${id}`;
  const salamkaut = `/salamkaut/${id}`;
  const aktifkan = `/checkout/${id}`;
  const tema = `/tema/${id}`;

  const shareText = `
❤️ UNDANGAN ${walimatulurus} ❤️
      
${url}
          
بِسْــــــــــــــــــمِ اللهِ الرَّحْمَنِ الرَّحِيْمِ
                        
Assalamualaikum wbt & Salam Sejahtera,

${parentName}

${message}
                        
${groomName} & ${brideName}
          
🗓️ ${weddingDay}, ${formattedDate}
📍 ${location}

Mohon kepada tetamu yang hadir untuk *menjawab RSVP*.

#${event}

Terima Kasih.
  `;

  const handleCopy = () => {
    const textarea = document.getElementById('invitationText');
    textarea.select();
    document.execCommand('copy');
    alert('Text copied to clipboard!');
  };

  const handleHashtag = () => {
    axios.put(`${process.env.REACT_APP_API_BASE_URL}/update-hashtag/${id}`, { event: event })
      .then(response => {
          console.log(response.data.message); // Log success message
          alert('Hashtag telah dikemaskini');
          window.location.reload();
      })
      .catch(error => {
          console.error('Error updating hashtag:', error);
      });
  };

  const [phoneNumber, setPhoneNumber] = useState(false);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/settings`)
      .then(response => setPhoneNumber(response.data.whatsapp_no))
      .catch(error => console.error('Error fetching settings:', error));
  }, []);

  return (
    <div>
      {/* <WhatsAppFAB />  */}
      <Helmet>
        <title>Tahniah | InviteKahwin.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {loading ? ( 
          <div className="fixed inset-0 flex flex-col items-center justify-center space-y-5">
            <FaSpinner className="text-4xl text-secondary-500 animate-spin" />
            <img src={logo} alt="Logo" className="h-8 mb-4" />
          </div>
        ) : (
          <div>
            <header className="w-full fixed bg-neutral-50 shadow-md py-4 z-10">
              <div className="flex items-center justify-center px-6">
                <a href="/"><img src={logo} alt="Logo" className='h-10' /></a>
              </div>
            </header>
            <div className="bg-gray-50 flex pt-20 justify-center min-h-screen">
              <div className=" px-8 py-8 max-w-md w-full">

                {status === 'unverified' ?(
                  <div className='flex items-center justify-between pb-2'>
                    <div>
                      {expired ? (
                        <p>Status: <span className='font-bold text-secondary-700'>Luput</span></p> // Display "Expired" when the time is up
                      ) : (
                        <>
                          <p>Status: <span className='font-bold'>Trial</span></p>
                          <p className='text-sm'>Luput dalam: {timeLeft.hours}j: {timeLeft.minutes}m: {timeLeft.seconds}s</p>
                        </>
                      )}
                    </div>
                    <a
                      href={aktifkan}
                      rel="noreferrer"
                      className='px-3 py-2 rounded-lg bg-purple-600 hover:bg-purple-700 text-neutral-50'>
                        Aktifkan
                    </a>
                  </div>
                ):(
                  <div className='pb-2'>
                    <p>Status: <span className='font-bold text-green-500'>Aktif</span></p>
                  </div>
                )}

                <div className='relative px-5 py-2 rounded-lg shadow-xl mb-8 bg-white'>
                  {/* Icons in the background */}
                  <div className="absolute inset-0 flex justify-center items-center opacity-30">
                    {/* Adjust the number of icons and their size as needed */}
                    <FaHeart className="text-red-300 text-6xl mx-2" />
                    <FaHeart className="text-red-300 text-6xl mx-2" />
                    <FaHeart className="text-red-300 text-6xl mx-2" />
                  </div>
  
                  {/* Content */}
                  <div className='relative py-8'>
                    <p className='capitalize'>{walimatulurus}</p>
                    <h2 className='text-2xl font-bold'>{groomName} & {brideName}</h2>
                    <p>{weddingDay}, {formattedDate}</p>
                  </div>
                </div>
                <div className='text-center'>
                  <div className='mb-4'>
                    <div className='flex items-center'>
                      <label className='text-lg p-2'>#</label>
                      <input
                        type="text"
                        value={event}
                        onChange={(e) => {
                          const value = e.target.value.replace(/[^a-zA-Z0-9]/g, ''); // Remove spaces and symbols
                          setEvent(value); // Set the cleaned value
                        }}
                        className="border px-5 py-3 mb-2 w-full"
                        placeholder="Enter hashtag"
                        required
                      />
                    </div>
                    <button
                      onClick={handleHashtag}
                      className="bg-primary-500 hover:bg-primary-700 text-neutral-50 px-3 py-2 rounded w-full"
                    >
                      Update #Hashtag
                    </button>
                  </div>
                  <textarea 
                    id="invitationText"
                    value={shareText}
                    readOnly
                    rows="10"
                    className="w-full p-2 border rounded"
                  />
                  <button
                    onClick={handleCopy}
                    className="bg-primary-500 hover:bg-primary-700 text-neutral-50 px-3 py-2 rounded w-full"
                  >
                    Copy & Share
                  </button>
                  <div className='flex items-center justify-between space-x-2 mt-2'>
                    <a
                      href={url}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-white text-center text-secondary-500 hover:bg-secondary-500 hover:text-neutral-50 px-3 py-2 rounded w-full border-solid border-2 border-secondary-500"
                    >
                      
                      View
                    </a>
                    <a
                      href={edit}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-white text-center text-secondary-500 hover:bg-secondary-500 hover:text-neutral-50 px-3 py-2 rounded w-full border-solid border-2 border-secondary-500"
                    >
                      Edit
                    </a>
                  </div>
                  <div className='my-6 flex items-stretch justify-around'>
                    <a
                      href={rsvp}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-white hover:bg-secondary-700 text-secondary-700 hover:text-neutral-50 p-3 rounded-lg border border-gray-200 w-1/4 flex flex-col items-center space-y-2"
                    >
                      <FaAddressBook className="text-3xl" />
                      <p className="text-xs">RSVP</p>
                    </a>
                    <a
                      href={music}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-white hover:bg-secondary-700 text-secondary-700 hover:text-neutral-50 p-3 rounded-lg border border-gray-200 w-1/4 flex flex-col items-center space-y-2"
                    >
                      <FaMusic className="text-3xl" />
                      <p className="text-xs">Lagu</p>
                    </a>
                    <a
                      href={salamkaut}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-white hover:bg-secondary-700 text-secondary-700 hover:text-neutral-50 p-3 rounded-lg border border-gray-200 flex flex-col items-center space-y-2"
                    >
                      <FaGift className="text-3xl" />
                      <p className="text-xs">Hadiah & Salam Kaut</p>
                    </a>
                    <a
                      href={tema}
                      target="_blank"
                      rel="noreferrer"
                      className="bg-white hover:bg-secondary-700 text-secondary-700 hover:text-neutral-50 p-3 rounded-lg border border-gray-200 flex flex-col items-center space-y-2"
                    >
                      <FaPalette className="text-3xl" />
                      <p className="text-xs">Ubah Tema</p>
                    </a>
                  </div>
                  {/* <div className='my-6 space-y-2'>
                    <a
                      href={rsvp}
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold text-center bg-green-400 hover:bg-green-500 text-neutral-50 px-5 py-5 rounded w-full block"
                    >
                      
                      RSVP
                    </a>
                    <a
                      href={music}
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold text-center bg-yellow-500 hover:bg-yellow-600 text-black px-5 py-5 rounded w-full block"
                    >
                      Background Music
                    </a>
                    <a
                      href={salamkaut}
                      target="_blank"
                      rel="noreferrer"
                      className="font-bold text-center bg-secondary-700 hover:bg-purple-600 text-neutral-50 px-5 py-5 rounded w-full block"
                    >
                      Salam Kaut & Hadiah
                    </a>
                  </div> */}
                    <a
                      href={`https://wa.me/${phoneNumber}`}
                      target="_blank"
                      rel="noreferrer"
                      className="flex items-center justify-center font-bold text-center bg-green-500 hover:bg-green-600 text-neutral-50 px-2 py-2 rounded-lg w-full"
                    >
                      <FaWhatsapp className="mr-2" /> Ada soalan?
                    </a>
                </div>
                <Recommended />
                {/* <Donate /> */}
                {/* <Feedback /> */}
                {/* <Poll /> */}
                {/* <Servis /> */}
  
              </div>
            </div>
          </div>
        )
      }
    </div>
    
  );
};

export default Manage;
