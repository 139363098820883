import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LogMasuk from '../components/LogMasuk';
import Auth from '../components/Auth';
import { FaSpinner } from 'react-icons/fa';
import logo from '../img/logo-black.png';
import logo2 from '../img/logo.png';
import RSVPList from '../components/RSVPList';
import axios from 'axios';
import EnableRSVP from '../components/EnableRSVP';
import NavBar from '../components/NavBar';

function RSVP() {
  const [login, setLogin] = useState(true);
  const [loading, setLoading] = useState(true);
  const [ownerEmail, setOwnerEmail] = useState('');
  const { id } = useParams();

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_BASE_URL}/clients/${id}`)
      .then(response => {
        const rsvp = response.data.rsvp;
        if(rsvp){
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
  }, [id]);
  

  return (
    <div>
      <Helmet>
        <title>RSVP | InviteKahwin.com</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
        <div> 
          <Auth id={id} setLogin={setLogin} setOwnerEmail={setOwnerEmail} />
          {login ? 
            (
              <div>
                <>
                  {loading && (<div className="fixed inset-0 h-screen w-full bg-white bg-opacity-80 flex flex-col items-center justify-center space-y-5">
                    <FaSpinner className="text-4xl text-secondary-500 animate-spin" />
                    <img src={logo2} alt="Logo" className="h-12 mb-4" />
                  </div>)}
                  {/* <NavBar /> */}
                  <header className="w-full bg-neutral-50 shadow-md py-4 z-10">
                    <div className="flex items-center justify-center px-6">
                      <a href="/"><img src={logo2} alt="Logo" className='h-10' /></a>
                    </div>
                  </header>
                  <div className="md:p-24 flex items-center justify-center">
                    <div className="bg-white px-8 py-12 rounded-md md:shadow-lg max-w-lg w-full">
                      <EnableRSVP />
                      <RSVPList setLoading={setLoading} />
                    </div>
                  </div>
                </>
              </div>
            ) : ( 
              <div>
                <LogMasuk ownerEmail={ownerEmail} setLogin={setLogin} />
              </div>
            )
          }
        </div>
    </div>
  );
}

export default RSVP;
